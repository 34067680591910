<template>
  <!-- how it works -->
  <div class="space-y-8">
    <h1 class="font-semibold text-3xl">How It Works</h1>
    <!-- items -->
    <div
      class="border border-gray-300 rounded p-4 pb-6 space-y-6"
      v-for="(item, index) in items"
      :key="index"
    >
      <h3 class="font-semibold text-lg">{{ item.title }}</h3>
      <div v-html="item.text" class="space-y-6" />
      <div v-if="item.button.display">
        <router-link
          :to="'/' + $store.state.selectedPartner.id + item.button.path"
          class="
            uppercase
            text-blue-600
            font-semibold
            cursor-pointer
            text-sm
            tracking-wide
            hover:bg-blue-200
            transition
            rounded-full
            px-3
            py-2
          "
        >
          {{ item.button.text }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: "Step 1: Select Packages",
        text: `<p>
               Choose which destinations will appeal to the most attendees in your event. Our team will be happy to help you choose travel packages to be your biggest crowd-pleasers. Print and display as many as you like.
               <strong>No up-front costs = all packages consigned = no risk to you!</strong>
               </p>`,
        button: {
          display: true,
          path: "/experiences",
          text: "Browse Experiences",
        },
      },
      {
        title: "Step 2: Showcase Them in Your Event",
        text: `<p>
              Whether your auction is live or silent, our packages will speak to your donors.
              <strong>You can sell as many packages as you like.</strong> Do you love one package more than others? Sell it multiple times to as many donors as you like, multiplying your profits as well.
              </p>`,
        button: {
          display: true,
          path: "/experiences",
          text: "Browse Experiences",
        },
      },
      {
        title: "Step 3: Winners Redeem",
        text: `<p>
              Once you’ve reported your winners and made payment, we’ll quickly email you the winner’s Congratulations emails. Simply forward those emails to your winners and
              <strong>we’ll take care of the rest.</strong>
              </p>
              <p>
              Your winners have access to both our knowledgeable team of booking specialists, as well as our
              <strong>exclusive online resort selection and reservations system.</strong>
              </p>
              <p>
              These resources empower your winners to book their vacation at their convenience 24/7/365.
              </p>
              <p>
              We look forward to making each of your winner’s vacation dreams a reality. Leverage our traveler feedback at your next event to create the marketing buzz you have always imagined.
              </p>`,
        button: {
          display: true,
          path: "/experiences",
          text: "Browse Experiences",
        },
      },
    ],
  }),
};
</script>