<template>
  <div class="space-y-6">
    <h1 class="font-semibold text-3xl">Contact Us</h1>
    <p>
      During office hours Monday through Friday 8:30 AM ET to 5:30 PM ET, we are
      eager to answer your email inquiry within same day. If you need immediate
      help or just prefer telephone contact feel free to call
      <a
        :href="
          'tel:' +
          $store.state.selectedPartner.phone
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .replaceAll('(', '')
            .replaceAll(')', '')
        "
        class="href"
        >{{ $store.state.selectedPartner.phone }}</a
      >
      anytime.
    </p>
    <p>
      <strong>Please feel free to contact us anytime </strong>
      to discuss your upcoming events and how we might help you to achieve your
      goals.
    </p>

    <!-- form error -->
    <div class="rounded-md bg-red-50 p-4 border border-red-300" v-if="error">
      <div class="flex">
        <div class="flex-shrink-0">
          <i class="mdi mdi-alert-circle-outline text-2xl text-red-600"></i>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">
            The form did not submit successfully
          </h3>
          <div class="mt-2 text-sm text-red-700">
            <p>
              We have not received your contact request. Please email us
              directly at
              <a href="mailto:booking@silentauctionvacations.com" class="href"
                >booking@silentauctionvacations.com.</a
              >
              Thank you.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- form success -->
    <div
      class="rounded-md bg-green-50 p-4 border border-green-300"
      v-if="success"
    >
      <div class="flex">
        <div class="flex-shrink-0">
          <i class="mdi mdi-check-circle-outline text-2xl text-green-600"></i>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-green-800">
            Form Successfully Submitted
          </h3>
          <div class="mt-2 text-sm text-green-700">
            <p>
              Please keep an eye on your inbox for our response within 3
              business days. Thank you.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- form -->
    <div class="space-y-6" v-if="!error && !success">
      <!-- name -->
      <div>
        <input
          @blur="v$.name.$touch()"
          v-model.trim="name"
          type="text"
          name="name"
          id="name"
          autocomplete="name"
          :disabled="loading"
          class="
            block
            w-full
            shadow-sm
            py-3
            px-4
            placeholder-gray-500
            rounded-md
            bg-gray-100
          "
          :class="
            v$.name.$error
              ? 'border-red-500  focus:ring-red-500 focus:border-red-500'
              : 'border-gray-400 focus:ring-blue-500 focus:border-blue-500'
          "
          placeholder="Name *"
        />
        <p class="text-red-500 font-semibold mt-2" v-if="v$.name.$error">
          {{ v$.name.$errors[0].$message }}
        </p>
      </div>
      <!-- email -->
      <div>
        <input
          @blur="v$.email.$touch()"
          v-model.trim="email"
          type="email"
          name="email"
          id="email"
          autocomplete="email"
          :disabled="loading"
          class="
            block
            w-full
            shadow-sm
            py-3
            px-4
            placeholder-gray-500
            rounded-md
            bg-gray-100
          "
          :class="
            v$.email.$error
              ? 'border-red-500  focus:ring-red-500 focus:border-red-500'
              : 'border-gray-400 focus:ring-blue-500 focus:border-blue-500'
          "
          placeholder="Email *"
        />
        <p class="text-red-500 font-semibold mt-2" v-if="v$.email.$error">
          {{ v$.email.$errors[0].$message }}
        </p>
      </div>
      <!-- message -->
      <div>
        <textarea
          @blur="v$.message.$touch()"
          :disabled="loading"
          v-model.trim="message"
          id="message"
          name="message"
          rows="4"
          class="
            block
            w-full
            shadow-sm
            py-3
            px-4
            placeholder-gray-500
            rounded-md
            bg-gray-100
          "
          :class="
            v$.message.$error
              ? 'border-red-500  focus:ring-red-500 focus:border-red-500'
              : 'border-gray-400 focus:ring-blue-500 focus:border-blue-500'
          "
          placeholder="Message *"
        ></textarea>
        <p class="text-red-500 font-semibold mt-2" v-if="v$.message.$error">
          {{ v$.message.$errors[0].$message }}
        </p>
      </div>
      <!-- submit button -->
      <div>
        <button
          :disabled="loading"
          @click="submit"
          class="
            uppercase
            bg-blue-600
            text-blue-50
            hover:bg-blue-700
            font-semibold
            cursor-pointer
            text-sm
            tracking-wide
            transition
            rounded-full
            px-4
            py-2
            space-x-2
            inline-flex
            items-center
            justify-between
          "
        >
          <span>Contact Us</span>
          <i v-if="!loading" class="mdi mdi-email-fast text-lg"></i>
          <svg
            v-if="loading"
            class="animate-spin h-5 w-5 text-blue-100"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-50"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import axios from "axios";
import NProgress from "nprogress";
export default {
  data: () => ({
    v$: useVuelidate(),
    toast: useToast(),
    name: "",
    email: "",
    message: "",
    success: false,
    error: false,
    loading: false,
  }),
  computed: {
    axiosUrl() {
      if (process.env.NODE_ENV == "development") return "http://localhost:5000";
      return "https://api.silentauctionvacations.com";
    },
  },
  methods: {
    submit() {
      this.v$.$validate();
      // if error
      if (this.v$.$error)
        return this.toast.error("Please check form for errors");
      // submit form
      NProgress.start();
      this.loading = true;
      let data = {
        selectedPartner: this.$store.state.selectedPartner,
        name: this.name,
        email: this.email,
        message: this.message,
        env: process.env.NODE_ENV,
      };
      let url = this.axiosUrl + "/auctionvacations.org/contact";
      axios.post(url, data).then((res) => {
        if (res.status == 200) {
          this.toast.success("Form submitted successfully");
          this.success = true;
          this.error = false;
          this.loading = false;
          NProgress.done();
          return;
        } else {
          this.toast.error("The form did not submit successfully");
          this.success = false;
          this.error = true;
          this.loading = false;
          NProgress.done();
          return;
        }
      });
    },
  },
  validations() {
    return {
      name: { required: helpers.withMessage("Name is required", required) },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter a valid email", email),
      },
      message: {
        required: helpers.withMessage("Message is required", required),
      },
    };
  },
};
</script>