<template>
  <div class="space-y-10">
    <div
      class="
        grid grid-cols-1
        sm:grid-cols-2
        gap-x-2 gap-y-10
        mt-4
        sm:gap-8
        md:gap-12
      "
    >
      <!-- how it works -->
      <div class="col-span-full sm:col-span-1">
        <HowItWorks />
      </div>
      <!-- contact -->
      <div class="col-span-full sm:col-span-1">
        <ContactForm />
      </div>
      <!-- best sellers -->
      <div class="col-span-2 space-y-10">
        <div>
          <hr />
        </div>
        <div class="pageTitle">
          <h1>Our Best Selling Packages</h1>
          <h3>A great starting point for any event</h3>
        </div>
        <RetailPackages :items="$store.state.retailPackages" />
      </div>
    </div>
  </div>
</template>

<script>
import RetailPackages from "@/components/RetailPackages";
import HowItWorks from "@/components/HowItWorks";
import ContactForm from "@/components/ContactForm";
export default {
  components: {
    RetailPackages,
    HowItWorks,
    ContactForm,
  },
};
</script>
