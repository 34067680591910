<template>
  <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
    <!-- loading -->
    <div class="mt-6" v-if="loading">
      <Loading />
    </div>
    <!-- package cards -->
    <div
      v-else
      v-for="(item, index) in items"
      :key="index"
      class="
        border border-gray-300
        rounded
        overflow-hidden
        flex flex-col
        space-y-2
      "
    >
      <!-- img -->
      <div>
        <router-link
          :to="'/' + $store.state.selectedPartner.id + '/p/' + item.packageCode"
        >
          <img
            :src="item.images[0].downloadUrl"
            class="object-cover object-center h-48 w-full cursor-pointer"
          />
        </router-link>
      </div>

      <!-- info -->
      <div class="flex-grow p-4 space-y-2">
        <!-- title -->
        <div
          v-html="item.title"
          class="font-semibold text-lg leading-normal pb-3"
        />
        <!-- numberNights etc -->
        <div
          class="flex items-center flex-wrap text-sm gap-4 sm:gap-3 md:gap-4"
        >
          <div class="flex items-center gap-x-3">
            <div class="inline-flex gap-x-2 items-center">
              <span class="mdi mdi-weather-night"></span>
              {{ item.numberNights }} Nights
            </div>
            <div class="inline-flex gap-x-2 items-center">
              <span class="mdi mdi-bed-outline"></span>
              {{ item.roomType.title }}
            </div>
          </div>
          <div>
            Package Code: <span class="uppercase">{{ item.packageCode }}</span>
          </div>
          <div v-if="$store.state.selectedPartner.displayCostToOrg">
            Cost to Org: ${{ item.costToOrg }}.00
          </div>
          <div>Max Retail Value: ${{ item.maxRetail }}.00</div>
        </div>
      </div>

      <!-- footer -->
      <div class="flex items-center justify-between py-4 px-2">
        <div>
          <router-link
            :to="
              '/' + $store.state.selectedPartner.id + '/p/' + item.packageCode
            "
            class="
              uppercase
              text-blue-600
              font-semibold
              cursor-pointer
              text-sm
              tracking-wide
              hover:bg-blue-200
              transition
              rounded-full
              px-3
              py-2
              ml-1
            "
          >
            More Info</router-link
          >
        </div>
        <div class="flex items-center space-x-2 mr-4">
          <!-- youtube -->
          <button
            @click="
              $store.state.youTubeModal = true;
              $store.state.youTubeItem = item;
            "
            class="
              cursor-pointer
              hover:bg-red-600 hover:bg-opacity-25
              transition
              rounded-full
              h-10
              w-10
              flex
              items-center
              justify-center
            "
          >
            <i class="mdi mdi-youtube text-2xl text-red-600"></i>
          </button>
          <!-- wishlist -->
          <!-- user -->
          <div
            @click="onWishList(item)"
            v-if="$store.state.user"
            class="
              cursor-pointer
              hover:bg-pink-600 hover:bg-opacity-25
              transition
              rounded-full
              h-10
              w-10
              flex
              items-center
              justify-center
            "
          >
            <i
              class="mdi text-2xl text-pink-600"
              :class="
                $store.state.userWishList.length &&
                $store.state.userWishList.some((i) => i == item.packageCode)
                  ? 'mdi-heart'
                  : 'mdi-heart-outline'
              "
            ></i>
          </div>
          <!-- !user -->
          <div
            @click="
              $store.state.authModal.display = true;
              $store.state.authModal.action = 'signup';
            "
            v-else
            class="
              cursor-pointer
              hover:bg-pink-600 hover:bg-opacity-25
              transition
              rounded-full
              h-10
              w-10
              flex
              items-center
              justify-center
            "
          >
            <i class="mdi text-2xl text-pink-600 mdi-heart-outline"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import { firebase, db } from "@/firebase";
import { useToast } from "vue-toastification";
import NProgress from "nprogress";
export default {
  props: ["items"],
  components: {
    Loading,
  },
  data: () => ({
    toast: useToast(),
    loading: true,
  }),
  methods: {
    onWishList(item) {
      if (!item) return;
      // check if item is already in wishList
      let isInWishList = this.$store.state.userWishList.some(
        (i) => i == item.packageCode
      );
      if (isInWishList) {
        // if in wishList => remove
        NProgress.start();
        db.collection("users")
          .doc(this.$store.state.userAccountData.id)
          .update({
            wishList: firebase.firestore.FieldValue.arrayRemove(
              item.packageCode
            ),
          })
          .then(() => {
            NProgress.done();
            this.toast.success("Removed from Wish List");
          });
      } else {
        // not in wishList => add to wishList
        NProgress.start();
        db.collection("users")
          .doc(this.$store.state.userAccountData.id)
          .update({
            wishList: firebase.firestore.FieldValue.arrayUnion(
              item.packageCode
            ),
          })
          .then(() => {
            NProgress.done();
            this.toast.success("Added to Wish List");
          });
      }
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        if (!items) return;
        return (this.loading = false);
      },
    },
  },
};
</script>